<template>
  <div class="container-fluid mt-4" v-if="TelefoniaStore.getTelefonia()">
    <div v-if="TelefoniaStore.getTelefonia()?.status">
      <div class="row">
        <div class="col-lg-6">
          <div class="row mt-2">
            <div class="col-12 mb-xl-0 mb-4">
              <Franquias />
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row mt-2">
            <div class="col-12 mb-xl-0 mb-4">
              <Ramais />
            </div>
          </div>
        </div>
      </div>

      <!--<div class="row mt-2">
        <div class="col-12 mb-xl-0 mb-4">
          <TabelaLigacoes />
        </div>
      </div>-->
    </div>

    <div class="row" v-else>
      <div class="col-lg-7 mt-2">
        <div class="card h-100">
          <div class="card-body p-3 h-100">
            <div class="row px-3 h-100">
              <div class="col-lg-6">
                <div class="d-flex flex-column h-100">
                  <h5 class="font-weight-bolder pt-4">
                    Telefonia e Central PABX
                  </h5>
                  <p class="mb-5">
                    Conheça nossos planos de telefonia e Central PABX. Mais
                    praticidade, múltiplas ferramentas e segurança para sua casa
                    ou seu negócio. Internet e telefonia: tudo na STAR.
                  </p>
                  <a
                    class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
                    target="_blank"
                    href="https://star.psi.br/telefonia"
                  >
                    Saiba mais
                    <i
                      class="fas fa-arrow-right text-sm ms-1"
                      aria-hidden="true"
                    ></i>
                  </a>
                </div>
              </div>
              <div
                class="col-lg-5 bg-cover ms-auto mt-lg-0 border-radius-lg"
                :style="{
                  backgroundImage:
                    'url(' +
                    require('@/assets/img-minhastar/telefonia-dashboard.jpg') +
                    ')',
                }"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-5 mt-2">
        <div class="card h-100 p-3">
          <div
            class="overflow-hidden position-relative border-radius-lg bg-cover h-100"
            :style="{
              backgroundImage:
                'url(' +
                require('@/assets/img-minhastar/mulher-adulta-trabalhando-tarde-da-noite-em-casa.jpg') +
                ')',
            }"
          >
            <span class="mask bg-gradient-dark"></span>
            <div class="card-body position-relative z-index-1 p-3 h-100">
              <div class="d-flex flex-column h-100">
                <h5 class="text-white font-weight-bolder mb-4 pt-2">
                  Entre em contato para maiores informações
                </h5>
                <p class="text-white mb-5">
                  Os serviços de telefonia da Star são a escolha inteligente
                  para quem busca qualidade, estabilidade e tecnologia 100%
                  digital na telefonia fixa. Com o Star Fone, você tem a
                  garantia de uma qualidade de som superior e um sinal estável,
                  que garante uma conexão ininterrupta em qualquer lugar.
                </p>
                <a
                  class="text-white font-weight-bold ps-1 mb-0 icon-move-left mt-auto"
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=555127472747&text=Ol%C3%A1!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20os%20servi%C3%A7os%20de%20telefonia!"
                >
                  Saiba mais
                  <i
                    class="fas fa-arrow-right text-sm ms-1"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardCliente from "../components/CardCliente.vue";
import TabelaLigacoes from "../components/TabelaLigacoes.vue";
import Franquias from "../components/Franquias.vue";
import Ramais from "../components/Ramais.vue";

import { useTelefoniaStore } from "../../stores/telefonia";
let TelefoniaStore;

export default {
  name: "Telefonia",
  components: {
    CardCliente,
    TabelaLigacoes,
    Franquias,
    Ramais,
  },
  data() {
    return {
      TelefoniaStore,
    };
  },
  async mounted() {},
  setup() {
    TelefoniaStore = useTelefoniaStore();
  },
};
</script>
