<template>
  <div class="card" style="max-height: 227.5px">
    <div class="card-header pb-0 px-3">
      <h6 class="mb-0">Numeros</h6>
    </div>
    <div class="card-body pt-4" style="overflow-y: auto">
      <ul class="list-group">
        <li
          v-for="(numero, index) of TelefoniaStore.getTelefonia().dados
            .ramais[0].did"
          :key="index"
          class="list-group-item border-0 d-flex ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column" style="width: 100%">
            <h6 class="mb-3 text-sm">
              {{ formatarNumero(numero.numero) }}
            </h6>
            <div class="mb-2" style="display: flex; align-items: center">
              <div
                class="text-xs"
                style="margin-right: 8px"
                :style="numero.sigame == null ? 'color: red' : 'color: #86d616'"
              >
                <b style="color: #67748e">Siga-me:</b>
                {{
                  numero.sigame == null
                    ? "Não habilitado"
                    : formatarNumero(numero.sigame)
                }}
              </div>
              <div
                v-if="numero.sigame != null"
                class="text-xs button-desativar"
              >
                Desativar
              </div>
            </div>
            <span class="mb-2 text-xs">
              <b>Aceita a cobrar:</b>
              {{ numero.aceita_ACB == 0 ? "Não" : "Sim" }}
            </span>
            <span class="text-xs">
              <b>Enviar DID:</b>
              {{ numero.enviar_did }}
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
const PhoneNumber = require("libphonenumber-js");
import { useTelefoniaStore } from "../../stores/telefonia";
import SoftButton from "@/components/SoftButton.vue";
let TelefoniaStore;

export default {
  name: "Ramais",
  components: {
    SoftButton,
  },
  data() {
    return {
      TelefoniaStore,
    };
  },
  methods: {
    formatarNumero(numero) {
      const numeroFormatado = PhoneNumber.format(
        PhoneNumber.parse(numero, "BR"),
        "National"
      );
      return numeroFormatado;
    },
    converterSegundosMinutos(segundos) {
      return segundos / 60;
    },
    calcularPorcentagem(franquia) {
      return (
        franquia.total_minutos_franquia - franquia.segundos_disponiveis / 60
      );
    },
  },
  setup() {
    TelefoniaStore = useTelefoniaStore();
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

html {
  scrollbar-color: #888;
  scrollbar-width: thin;
}

.button-desativar {
  border: 1px solid #86d616;
  color: #86d616;
  font-weight: 600 !important;
  text-transform: uppercase;
  border-radius: 6px;
  padding: 6px;
  transition: all 0.4s ease;
  user-select: none;
}

.button-desativar:hover {
  cursor: pointer;
}

.button-desativar:active {
  transform: scale(1.1);
}
</style>
