<template>
  <div class="card" style="max-height: 227.5px">
    <div class="card-header pb-0 px-3">
      <h6 class="mb-0">Franquias</h6>
    </div>
    <div class="card-body pt-4" style="overflow-y: auto">
      <ul class="list-group">
        <li
          v-for="(franquia, index) of TelefoniaStore.getTelefonia().dados
            .ramais[0].ramais_franquias"
          :key="index"
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-3 text-sm">
              {{
                franquia.LC != ""
                  ? "LICACOES LOCAIS"
                  : franquia.LDN != ""
                  ? "LIGACOES DE LONGA DISTANCIA"
                  : "LIGACOES PARA CELULARES"
              }}
            </h6>
            <span class="mb-2 text-xs">
              <b>Minutos disponíveis:</b>
              {{ converterSegundosMinutos(franquia.segundos_disponiveis) }} /
              {{ franquia.total_minutos_franquia }}
            </span>
            <span class="mb-2 text-xs">
              <BarraProgresso
                :id="index"
                :porcentagem="calcularPorcentagem(franquia)"
              />
              <b>Você consumiu:</b>
              {{ (calcularPorcentagem(franquia) * 100).toFixed(0) }}%
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { useTelefoniaStore } from "../../stores/telefonia";
import BarraProgresso from "../components/BarraProgresso.vue";

let TelefoniaStore;

export default {
  name: "Franquias",
  components: {
    BarraProgresso,
  },
  data() {
    return {
      TelefoniaStore,
    };
  },
  methods: {
    converterSegundosMinutos(segundos) {
      return segundos / 60;
    },
    calcularPorcentagem(franquia) {
      let minutos = franquia.segundos_disponiveis / 60;
      return (
        (franquia.total_minutos_franquia - minutos) /
        franquia.total_minutos_franquia
      );
    },
  },
  setup() {
    TelefoniaStore = useTelefoniaStore();
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

html {
  scrollbar-color: #888;
  scrollbar-width: thin;
}
</style>
